import React, { Component } from "react";
import { Table, Input, Tag, Space, DatePicker,Modal, Descriptions, Button } from "antd";
import ReactExport from "react-data-export";
import ReactDataSheet from "react-datasheet";
import { getRefuelProcumentExportSheet, getRefuelPurchaseTransactionExport } from "../../api/index";
import ReactLoading from 'react-loading';
import { getDonationCategories, getData } from "../../actions/index";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import '../master-data/style.css';
import { EyeOutlined } from "@ant-design/icons";

const moment = require('moment');

const { RangePicker } = DatePicker;

class ProcurementRefuelFullView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      filterTable: null,
      data_list: [],
      data_list_site: [],
      data_list_purchase: [],
      data_list_Transaction: [],
      startDate: "",
      endDate: "",
      loading: false,
      openModal: false,
      selectedData: {}
    };
  }

  componentDidMount() {
    if (JSON.parse(localStorage.getItem('serviceTypeSet')) == null) {
      const { history } = this.props;
      history.push("/");
    } else {
      this.getSetPageData(JSON.parse(localStorage.getItem('serviceTypeSet')) , 15)
    }
    this.props.getData();
  }

  getSetPageData =(serviceTypeIs,Pageid) =>  {
    if(serviceTypeIs.serviceType.filter(checkuser=>checkuser?.id==Pageid)[0] == null ){
      const { history } = this.props;
      history.push("/not-allowed");
    }
  };

  getDataExport = async (data) => {
    var res = await getRefuelProcumentExportSheet(data);
    if (res.success) {
      this.setState({
        loading: false,
        data_list: res.data,
        data_list_purchase: res.data,
      });
    }
  };

  getPurchaseTransaction = async (data) => {
    //console.log(data);
    var res = await getRefuelPurchaseTransactionExport(data);
    //console.log("getRefuelPurchaseTransactionExport");
    //console.log(res);
    if (res.success) {
      // this.createDataset(res.data);
      //this.createDatasetPurchase(res.data);
      this.setState({
        loading: false,
        data_list_Transaction: res.data,
        //data_list_purchase: res.data,
      });
    }
  };

  onChangeDate = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      startDate: dateString,
    });
  };
  onChangeDate2 = (date, dateString) => {
    //console.log(date);
    //console.log(dateString);
    this.setState({
      endDate: dateString,
    });
  };
  searchClick = () => {
    //console.log("search");
    if (this.state.startDate != "" && this.state.endDate != "") {
      var data = {
        "startDate": this.state.startDate,
        "endDate": this.state.endDate,

      };
      this.setState({
        loading: true,
      });
      this.getDataExport(data);
      this.getPurchaseTransaction(data);
    }

  };
  render() {
    const columns = [
      {
        title: "Code",
        dataIndex: "code",
        key: "code",
        render: (item) => <span>{item}</span>,
      },
      {
        title: "Region",
        dataIndex: "region",
        key: "region",
        render: (item) => <span>{item}</span>,
      },

      {
        title: "Created Date",
        dataIndex: "createdTime",
        key: "createdTime",
        render: (item) => <span>{moment((item)).format('YYYY-MM-DD')}</span>,
      },
      {
        title: "Created Time",
        dataIndex: "createdTime",
        key: "createdTime",
        render: (item) => <span>{moment((item)).format('HH:mm:ss')}</span>,
      },
    {
        title: "Approved Quantity",
        dataIndex: "approvedQuantity",
        key: "approvedQuantity",
        render: (item) => <span>{item}</span>,
        },
        {
        title: "Total Quantity",
        dataIndex: "totalQuantity",
        key: "totalQuantity",
        render: (item) => <span>{item}</span>,
        },
        {
        title: "In Hand Quantity",
        dataIndex: "inHandQuantity",
        key: "inHandQuantity",
        render: (item) => <span>{item}</span>,
        },
        {
        title: "Disaster Quantity",
        dataIndex: "disasterQuantity",
        key: "disasterQuantity",
        render: (item) => <span>{item}</span>,
        },
        {
        title: "Fuel Consumption",
        dataIndex: "fuelConsumption",
        key: "fuelConsumption",
        render: (item) => <span>{item}</span>,
        },
        {
            title: "Action",
            dataIndex: "code",
            key: "code",
            render: (text, record) => (
                <Space size="middle">
                    <Button
                        shape='circle'
                        size='small'
                        icon={<EyeOutlined />}
                        type="primary"
                        onClick={() => {
                            this.setState({openModal: true, selectedData: record})
                        }}
                    />
                </Space>
            )
          },
    ];
    const timestamp = Date.now();

    return (
      <div class="container-fluid">
        <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="row">
                    <div class="col">
                      <h4 class="page-title">Refuel Procurement-Data View</h4>
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Refuel</a></li>
                        <li class="breadcrumb-item"><a href="javascript:void(0);">Refuel Procurement-Data View</a></li>

                      </ol>
                    </div>
                    <div class="col-auto align-self-center">
                      <a href="#" class="btn btn-sm btn-outline-primary" id="Dash_Date">
                        <span class="day-name" id="Day_Name">Today:</span>&nbsp;
                        <span class="" id="Select_date">{new Date().toUTCString().slice(5, 16)}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar align-self-center icon-xs ms-1"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: 15 }}>
                <div className="col-md-3 col-lg-3 col-xl-3">
                    <DatePicker
                        placeholder="Start Date"
                        className='borderedSelect'
                        style={{ width: "100%", height: 34 }}
                        onChange={this.onChangeDate}
                    />
                </div>
                <div className="col-md-3 col-lg-3 col-xl-3">
                    <DatePicker
                        placeholder="End Date"
                        className='borderedSelect'
                        style={{ width: "100%", height: 34 }}
                        onChange={this.onChangeDate2}
                    />
                </div>
                <div className="col-md-6 col-lg-6 col-xl-6">
                    <button
                        onClick={this.searchClick}
                        class="btn btn-primary"
                        style={{ background: "#38B36E", borderColor: "#38B36E", borderRadius: '5px', width: 100, height: 33 }}
                    >
                        <i class="fas fa-search" /> <span className='textStyles-small'>Search</span>
                    </button>
                </div>
                <div className="col-lg-12 col-xl-12" style={{ marginTop: 15 }}>
                    <div className="card">
                        <div className="card-body" style={{ borderRadius: '10px' }}>
                            <Table
                                size="small"
                                columns={columns}
                                className='table-striped-rows textStyles-small'
                                loading={this.state.loading}
                                dataSource={this.state.data_list}
                            />
                        </div>
                    </div>
                </div>

                <Modal
                    title={<span className="textStyles-small" style={{fontSize: 14}}>Procurement Details : {this.state.selectedData.code}</span>}
                    visible={this.state.openModal}
                    onCancel={() => this.setState({ openModal: false, selectedData: {} })}
                    footer={null}
                    width={800}
                >
                    <Descriptions bordered column={2} size="small">
                        <Descriptions.Item label={<span className="textStyles-small">Procurement Code</span>}><span className="textStyles-small">{this.state.selectedData.code}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Region</span>}><span className="textStyles-small">{this.state.selectedData.region}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Sub Region</span>}><span className="textStyles-small">{this.state.selectedData.subRegion}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Vendor</span>}><span className="textStyles-small">{this.state.selectedData.vendor}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Team</span>}><span className="textStyles-small">{this.state.selectedData.team}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Created Time</span>}><span className="textStyles-small">{moment(this.state.selectedData.createdTime).format('YYYY-MM-DD HH:mm:ss')}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Created By</span>}><span className="textStyles-small">{this.state.selectedData.createdBy}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Approved Status</span>}><span className="textStyles-small">{this.state.selectedData.approveStatus}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Approved Time</span>}><span className="textStyles-small">{moment(this.state.selectedData.approvedTime).format('YYYY-MM-DD HH:mm:ss')}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Apprved Remark</span>}><span className="textStyles-small">{this.state.selectedData.apprvedRemark}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Rejected Time</span>}><span className="textStyles-small">{moment(this.state.selectedData.rejectedTime).format('YYYY-MM-DD HH:mm:ss')}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Requested Quantity</span>}><span className="textStyles-small">{this.state.selectedData.requestedQuantity}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">CF Qty</span>}><span className="textStyles-small">{this.state.selectedData.carriedForwardQuantity}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Inhand Quantity</span>}><span className="textStyles-small">{this.state.selectedData.inHandQuantity}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Approved Quantity</span>}><span className="textStyles-small">{this.state.selectedData.approvedQuantity}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Total Qty In Procuement</span>}><span className="textStyles-small">{this.state.selectedData.totalQuantity}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Disaster Quantity</span>}><span className="textStyles-small">{this.state.selectedData.disasterQuantity}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Purchsed Qty (L)</span>}><span className="textStyles-small">{this.state.selectedData.purchasedQuantity}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Purchased  Amount (RS)</span>}><span className="textStyles-small">{this.state.selectedData.purchasedAmount}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Balance Purchse Qty</span>}><span className="textStyles-small">{this.state.selectedData.balncePurchasedQuantity}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Fuel Consumption</span>}><span className="textStyles-small">{this.state.selectedData.fuelConsumption}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Current Balance</span>}><span className="textStyles-small">{this.state.selectedData.currentBalance}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Procument Status</span>}><span className="textStyles-small">{this.state.selectedData.status}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Closed Time</span>}><span className="textStyles-small">{moment(this.state.selectedData.closedTime).format('YYYY-MM-DD HH:mm:ss')}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Update Inhand Quantity</span>}><span className="textStyles-small">{this.state.selectedData.updateInhandQuantity}</span></Descriptions.Item>
                        <Descriptions.Item label={<span className="textStyles-small">Approved</span>}><span className="textStyles-small">{this.state.selectedData.approved ? "Yes" : "No"}</span></Descriptions.Item>
                    </Descriptions>
                </Modal>
            </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    organization_count: state.organization.length,
    regions: state.regions,
    depots: state.depots,
    sites_count: state.sites.length,
    vendor_count: state.vendor.length,
    roles_count: state.roles.length,
  };
}

export default withRouter(connect(mapStateToProps, { getDonationCategories, getData })(
  ProcurementRefuelFullView
));